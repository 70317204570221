<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      :visible="true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      :class="{'d-none': !visibleModal }"
      @change="hideModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Contactos') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <!-- Barra pesquisa-->
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchContact"
                value=""
                :placeholder="$t('Pesquisar')"
              />
            </b-input-group>
          </div>
        </div>

        <!-- Listagem dos contactos -->
        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-list-with-search"
          :settings="configSettingsScroll"
        >
          <ul
            v-if="getContacts.length > 0"
            class="email-media-list"
          >
            <b-media
              v-for="contact in getContacts"
              :key="contact.id"
              tag="li"
              no-body
            >
              <b-media-aside class="media-left mr-50">
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :src="contact.photo"
                />
              </b-media-aside>
              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 class="mb-25">
                      {{ contact.name }}
                    </h5>
                    <span
                      v-if="contact.business"
                      class="text-truncate"
                    >{{ contact.business }}</span>
                    <span v-else>{{ $t('Empresa não definida') }}</span>
                  </div>
                </div>
                <div class="mail-message">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    block
                    variant="outline-primary"
                    class="mt-2"
                    @click.stop.prevent="chooseContact(contact)"
                  >
                    {{ $t('Selecionar') }}
                  </b-button>
                </div>
              </b-media-body>
            </b-media>
          </ul>
          <div
            class="no-results"
            :class="{'show': !getContacts.length}"
          >
            <h5>{{ $t('Nenhum resultado') }}</h5>
          </div>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="sw-button-action"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BButton, BFormInput, BInputGroup, BInputGroupPrepend, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { filterAndSortArray } from 'multiple-array-filter'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BSidebar,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  mixins: [
    sizeIconsAction,
    mixFormGeral,
  ],
  props: {
    chooseContact: {
      type: Function,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchContact: '',
      visibleModal: true,
    }
  },
  computed: {
    getContacts() {
      const filters = [
        { field: 'name', search: this.searchContact },
        { field: 'business', search: this.searchContact },
        { field: 'phones_txt', search: this.searchContact },
        { field: 'emails_txt', search: this.searchContact },
      ]

      const result = filterAndSortArray(this.contacts, filters, 'name')
      return result
    },
  },
  methods: {
    hideModal() {
      this.$emit('close-search-contacts-device')
    },
    setVisibleModal(val) {
      this.visibleModal = val
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-user-list ::v-deep{
  .email-media-list li {
    animation:none!important;
    animation-fill-mode:none!important;
  }
}
</style>
